/* eslint-disable import/first */
import { lazy, memo } from 'react'
import { Route, Switch } from 'react-router-dom'

// LEGACY IMPORT ROUTING PLEASE DONT IMPORT HERE DELETED SOON ~~~~~~~~~
import {
  healthyPage,
  // login,
  resetPassword,
  printPreviewSupplyOrder,
  printPreviewInbound,
  newPassowrd,
  grnprint,
  printPreviewPickingList,
  printPreviewGrn,
  kittingPrintPreviewPickingList,
  dashboard,
} from 'config/routes'
import PrivateRoute from 'middleware/privateRoute'

const ForgotPassword = lazy(() => import('pages/ForgotPassword'))
const NewPassword = lazy(() => import('pages/NewPassword'))
const Dashboard = lazy(() => import('pages/Dashboard'))
const GrnPrint = lazy(() => import('pages/Grn/Print'))
const PrintSupplyOrder = lazy(() => import('pages/PrintSupplyOrder'))
const PrintInbound = lazy(() => import('pages/PrintInbound'))
const PrintPreviewPickingList = lazy(() => import('pages/PrintPreviewPickingList'))
const PrintPreviewGRN = lazy(() => import('pages/PrintPreviewGRN'))
const KittingPrintPreviewPickingList = lazy(() => import('pages/KittingPrintPreviewPickingList'))
// LEGACY IMPORT ROUTING PLEASE DONT IMPORT HERE DELETED SOON ~~~~~~~~~

import FetchRBAC from 'middleware/FetchRBAC'
import DashboardWrapper from 'components/Dashboard'
import AuthRouter, { AuthRouterDetail } from 'features/Auth/router'
import OrderRouter, { OrderRouterDetail } from 'features/Order/router/router'
import CampaignAndDiscountRouter, {
  CampaignAndDiscountRouterDetail,
} from 'features/CampaignAndDiscount/router'
import AccountRouter, { AccountRouterDetail } from 'features/Account/router'
import CategoryAndPosisiRouter, {
  CategoryAndPositionRouterDetail,
} from 'features/CategoryAndPosisi/router'
import RackRouter, { RackRouterDetail } from 'features/Rack/router'
import AstroEventRouter, { AstroEventRouterDetail } from 'features/AstroEvent/router'
import LoyaltyRouter, { LoyaltyRouterDetail } from 'features/Loyalty/router'
import SKPRouter, { SKPRouterDetail } from 'features/Enterprise/SKP/router/router'
import FraudRouter, { FraudRouterDetail } from 'features/Fraud/router'
import ProductRouter, { ProductRouterDetail } from 'features/Product/router'
import HRISRouter, { HRISRouterDetail } from 'features/Enterprise/HRIS/router'
import ReorderListRouter, { ReorderListRouterDetail } from 'features/Enterprise/ReorderList/router'
import CapacityHandler, {
  CapacityHandlerRouterDetail,
} from 'features/Enterprise/CapacityHandler/router'
import PaymentRouter, { PaymentRouterDetail } from 'features/Payment/router'
import PromotionRouter, { PromotionRouterDetail } from 'features/Promotion/router'
import HCURouter, { HCURouterDetail } from 'features/HCU/router'
import BannerAndTickerRouter, { BannerAndTickerRouterDetail } from 'features/BannerAndTicker/router'
import LocationRouter, { LocationRouterDetail } from 'features/Location/router'
import Vendor, { VendorRouterDetail } from 'features/Enterprise/Vendor/router'
import SOAutomationRouter, {
  SOAutomationRouterDetail,
} from 'features/Enterprise/SOAutomation/router'
import ABAdminRouter, { ABAdminRouterDetail } from 'features/ABAdmin/router'
import SchedulesRouter, { SchedulesRouterDetail } from 'features/Enterprise/Schedules/router'

import SupplyOrderRouter from 'features/SupplyOrder/router/router'
import PublicRouter from './publicRouter'
import ProtectedRouter from './privateRouter'

const RootRouter = [
  ...AuthRouter,
  ...CategoryAndPosisiRouter,
  ...AstroEventRouter,
  ...OrderRouter,
  ...HCURouter,
  ...CampaignAndDiscountRouter,
  ...AccountRouter,
  ...RackRouter,
  ...LoyaltyRouter,
  ...SupplyOrderRouter,
  ...SKPRouter,
  ...FraudRouter,
  ...ProductRouter,
  ...HRISRouter,
  ...ReorderListRouter,
  ...CapacityHandler,
  ...PaymentRouter,
  ...PromotionRouter,
  ...BannerAndTickerRouter,
  ...LocationRouter,
  ...Vendor,
  ...SOAutomationRouter,
  ...ABAdminRouter,
  ...SchedulesRouter,
]

const filteredRouter = RootRouter.reduce(
  (acc, item) => {
    if (item.routeType === 'PUBLIC_ONLY' || item.routeType === 'PUBLIC') {
      acc.public.push(item)
    } else if (item.routeType === 'PRIVATE' && item.isDashboard) {
      acc.privateDashboard.push(item)
    } else if (item.routeType === 'PRIVATE' && !item.isDashboard) {
      acc.privateNoLayout.push(item)
    }
    return acc
  },
  {
    public: [] as typeof RootRouter,
    privateDashboard: [] as typeof RootRouter,
    privateNoLayout: [] as typeof RootRouter,
  },
)

export const RouterDetails = {
  ...AuthRouterDetail,
  ...CategoryAndPositionRouterDetail,
  ...AstroEventRouterDetail,
  ...OrderRouterDetail,
  ...HCURouterDetail,
  ...CampaignAndDiscountRouterDetail,
  ...AccountRouterDetail,
  ...RackRouterDetail,
  ...LoyaltyRouterDetail,
  ...SKPRouterDetail,
  ...FraudRouterDetail,
  ...ProductRouterDetail,
  ...HRISRouterDetail,
  ...ReorderListRouterDetail,
  ...CapacityHandlerRouterDetail,
  ...PaymentRouterDetail,
  ...PromotionRouterDetail,
  ...BannerAndTickerRouterDetail,
  ...LocationRouterDetail,
  ...VendorRouterDetail,
  ...SOAutomationRouterDetail,
  ...ABAdminRouterDetail,
  ...SchedulesRouterDetail,
}

export type RouterDetailsType = typeof RouterDetails

const RouterApps = () => (
  <Switch>
    <Route path={healthyPage} exact>
      <PublicRouter>
        <h1>This Page Was Healthy</h1>
      </PublicRouter>
    </Route>
    <Route path={resetPassword} exact>
      <PublicRouter>
        <ForgotPassword />
      </PublicRouter>
    </Route>
    <Route path={newPassowrd}>
      <PublicRouter>
        <NewPassword />
      </PublicRouter>
    </Route>

    {filteredRouter.public.map(({ Component, ...eachRoute }) => (
      <Route key={eachRoute.name} path={eachRoute.path} exact>
        {eachRoute.routeType === 'PUBLIC_ONLY' && (
          <PublicRouter>
            <Component />
          </PublicRouter>
        )}
        {eachRoute.routeType === 'PUBLIC' && <Component />}
      </Route>
    ))}

    {filteredRouter.privateNoLayout.map(({ Component, ...eachRoute }) => (
      <Route key={eachRoute.name} path={eachRoute.path} exact>
        <ProtectedRouter
          allowedRoles={eachRoute.allowedRoles}
          isUsingRBAC={!!(eachRoute.multiplePageID || eachRoute.rbacPageID)}
        >
          <FetchRBAC
            pageID={eachRoute.rbacPageID}
            multiplePageIDMatchWithPath={eachRoute.multiplePageID}
            path={eachRoute.path}
          >
            <Component />
          </FetchRBAC>
        </ProtectedRouter>
      </Route>
    ))}

    <Route path={dashboard}>
      <DashboardWrapper>
        <>
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>

          {filteredRouter.privateDashboard.map(({ Component, ...eachRoute }) => (
            <Route key={eachRoute.name} path={eachRoute.path} exact>
              <ProtectedRouter
                allowedRoles={eachRoute.allowedRoles}
                isUsingRBAC={!!(eachRoute.multiplePageID || eachRoute.rbacPageID)}
              >
                <FetchRBAC
                  pageID={eachRoute.rbacPageID}
                  multiplePageIDMatchWithPath={eachRoute.multiplePageID}
                  path={eachRoute.path}
                >
                  <Component />
                </FetchRBAC>
              </ProtectedRouter>
            </Route>
          ))}
        </>
      </DashboardWrapper>
    </Route>

    <Route component={GrnPrint} path={grnprint} exact />
    <PrivateRoute>
      <Route component={PrintSupplyOrder} path={printPreviewSupplyOrder} exact />
      <Route component={PrintInbound} path={printPreviewInbound} exact />
      <Route component={PrintPreviewPickingList} path={printPreviewPickingList} exact />
      <Route component={PrintPreviewGRN} path={printPreviewGrn} exact />
      <Route
        component={KittingPrintPreviewPickingList}
        path={kittingPrintPreviewPickingList}
        exact
      />
    </PrivateRoute>
  </Switch>
)

export default memo(RouterApps)
