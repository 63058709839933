import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const SLICE_NAME = 'orderDetail'

export type CustomerType = {
  name: string
  phone: string
  address: string
  latitude: number
  longitude: number
  addressDetail: string
  driverNote: string
  labelAddress: string
}

export type VoucherType = {
  benefitType: string
  code: string
  amount: number
  startPeriod: number
  endPeriod: number
  minPurchase: number
}

export type PlatformFeeType = {
  amount: number
  paymentFee: number
  packagingFee: number
}

export type PaymentType = {
  method: string
  name: string
  amount: number
  partner: string
  deliveryFee: number
  voucher: VoucherType
  voucherList: Nullable<VoucherType[]>
  astroBalance: number
  totalCoinUsed: number
  totalCoinEarned: number
  totalPriceItem: number
  platformFee: PlatformFeeType
  parentId: number
}

export type TimeslotType = {
  minArrivalAt: number
  maxArrivalAt: number
}
export interface OrderDetailInterface {
  type: 'INSTANT' | 'SUPER' | 'GRAB_MART' | 'ASTRO_BALANCE' | 'KITCHEN' | 'SUPER_V2'
  invoice: string
  sla: string
  orderCreatedAt: number
  status: string
  note: string
  grabOrderId: string
  grabBookingCode: string
  referenceOrder: string
  customer: CustomerType
  payment: PaymentType
  timeslot: TimeslotType
}

export type HubType = {
  type: 'HUB' | 'MEGAHUB' | 'MEGAHUB 2.0'
  name: string
}

export type ProductComponentType = {
  rack: string
  name: string
  sku: string
  productQty: number
  scannedQty: number
}
export type ProductModifierType = {
  id: number
  name: string
  modifier_variants: {
    id: string
    name: string
    modifier_type: string
    change_type: string
    price_change: number
  }[]
}

export type TicketDetailType = {
  name: string
  email: string
}

export type ProductType = {
  type: string
  rack: string
  name: string
  sku: string
  price: number
  totalPrice: number
  scannedQty: number
  productQty: number
  components: ProductComponentType[]
  specialType: string
  productModifier: string
  ticketDetail: TicketDetailType
  modifiers: ProductModifierType[]
}

export type StaffType =
  | {
      role: 'PICKER' | 'PACKER' | 'SUPER_PICKER' | 'SUPER_PACKER'
      name: string
      phone: never
      externalDriverPartner: never
    }
  | {
      role: 'DRIVER' | 'SUPER_DRIVER'
      name: string
      phone: string
      externalDriverPartner: string
    }

export type ExternalDriverType = {
  partner: string
  serviceName: string
  orderId: string
  awb: string
  trackUrl: string
}

export type ActivityType = {
  name: string
  createdAt: number
  note?: string
  imageUrl?: string
  status: boolean
  originLatitude?: number
  originLongitude?: number
  destinationLatitude?: number
  destinationLongitude?: number
  mapsUrl?: string
  hub?: string
}

export type ActivityPicType = {
  hub: string
  hubType: 'HUB' | 'MEGAHUB'
  staff: StaffType[]
}

export interface OrderActivitiesInterface {
  orderId: number
  orderType: string
  pic: ActivityPicType[]
  externalDriver: ExternalDriverType
  activities: ActivityType[]
}

export interface OrderProductsInterface {
  orderId: number
  hub: HubType[]
  products: ProductType[]
}

export type ExternalDriverHistoryLogisticType = {
  provider: string
  service_type: string
  service_code: Nullable<string>
}

export type ExternalDriverHistoryDriverType = {
  full_name: string
  phone_number: string
  plate_number: string
}

export type ExternalDriverHistoryStatusType = {
  last_status: string
  history_status: Array<{
    status: string
    remote_status: string
    remote_status_code: string | number
    remote_description: string
    timestamp: number
  }>
}

export type ExternalDriverHistoryAttributeType = {
  created_at: number
  created_by: string
  updated_at: number
  updated_by: string
  generation: {
    reason: string
    actor: string
  }
  cancelation: {
    reason: string
    actor: string
  }
  use_shipper: boolean
  url_live_track: string
  url_proof_of_delivery: string
}

export type ExternalDriverHistoryDetailsType = {
  id: number
  created_at: number
  created_by: string
  updated_at: number
  updated_by: string
  is_active: boolean
  reference_id: string | number
  remote_reference_id: string
  remoteId: string
  airwaybill_number: string
  logistic: ExternalDriverHistoryLogisticType
  driver: ExternalDriverHistoryDriverType
  status: ExternalDriverHistoryStatusType
}
export interface ExternalDriverHistoryInterface {
  details: ExternalDriverHistoryDetailsType
  attribute: ExternalDriverHistoryAttributeType
}

const defaultExternalDriverHistory: ExternalDriverHistoryInterface[] = []

const defaultOrderActivities: OrderActivitiesInterface = {
  orderId: 0,
  orderType: '',
  pic: [],
  externalDriver: {
    partner: '',
    serviceName: '',
    orderId: '',
    awb: '',
    trackUrl: '',
  },
  activities: [],
}

const defaultOrderProducts: OrderProductsInterface = {
  orderId: 0,
  hub: [],
  products: [],
}

const defaultOrderDetail: OrderDetailInterface = {
  type: 'INSTANT',
  invoice: '',
  sla: '',
  orderCreatedAt: 0,
  status: '',
  note: '',
  grabOrderId: '',
  grabBookingCode: '',
  referenceOrder: '',
  customer: {
    name: '',
    phone: '',
    address: '',
    latitude: 0,
    longitude: 0,
    addressDetail: '',
    driverNote: '',
    labelAddress: '',
  },
  payment: {
    method: '',
    name: '',
    amount: 0,
    partner: '',
    deliveryFee: 0,
    voucher: {
      benefitType: '',
      code: '',
      amount: 0,
      startPeriod: 0,
      endPeriod: 0,
      minPurchase: 0,
    },
    voucherList: null,
    astroBalance: 0,
    totalCoinUsed: 0,
    totalCoinEarned: 0,
    totalPriceItem: 0,
    platformFee: {
      amount: 0,
      paymentFee: 0,
      packagingFee: 0,
    },
    parentId: 0,
  },
  timeslot: {
    minArrivalAt: 1678723200000,
    maxArrivalAt: 1678726800000,
  },
}

export interface OrderDetailStateInterface {
  orderDetail: OrderDetailInterface
  orderProducts: OrderProductsInterface
  orderActivities: OrderActivitiesInterface
  externalDriverHistory: ExternalDriverHistoryInterface[]
}

const initialState: OrderDetailStateInterface = {
  orderDetail: defaultOrderDetail,
  orderProducts: defaultOrderProducts,
  orderActivities: defaultOrderActivities,
  externalDriverHistory: defaultExternalDriverHistory,
}

const orderDetailSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setOrderDetail: (state, action: PayloadAction<OrderDetailInterface>) => {
      state.orderDetail = action.payload
    },
    setOrderProducts: (state, action: PayloadAction<OrderProductsInterface>) => {
      state.orderProducts = action.payload
    },
    setOrderActivities: (state, action: PayloadAction<OrderActivitiesInterface>) => {
      state.orderActivities = action.payload
    },
    setDriverHistory: (state, action: PayloadAction<ExternalDriverHistoryInterface[]>) => {
      state.externalDriverHistory = action.payload
    },
  },
})

export const { setOrderDetail, setOrderProducts, setOrderActivities, setDriverHistory } =
  orderDetailSlice.actions
export default orderDetailSlice.reducer
