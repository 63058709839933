import { ImageVariantIdType, ImageUploadPayloadType } from '@astronautsid/wpe-utils'
import axios from 'axios'

import AxiosInstance from 'config/apiServiceApiGateway'
import { ServiceInternalImageRules, ServiceInternalImageUpload } from 'config/apiServiceImage'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export type ShiftNameType =
  | 'Pagi'
  | 'Siang'
  | 'Malam'
  | 'Izin'
  | 'Off Day'
  | 'Lembur Hari Off'
  | 'Rotasi Staff'
  | 'Custom'

export type ShiftUnionType = 'custom' | 'onleave' | 'off' | 'rotation' | 'emergency'

export interface ApprovalInterface {
  id: number
  issue_notes: string
  status: number
  status_notes: string
}

export interface AttendanceInterface {
  id: number
  schedule_id: number
  description: string
  shift_name: ShiftUnionType
  checkin_date: string
  precheckin_time: string
  checkin_time: string
  checkin_img: string
  checkin_note: {
    code: string
    description: string
  }
  checkin_note_img: string
  checkout_date: string
  precheckout_time: string
  checkout_time: string
  checkout_img: string
  checkout_note: {
    code: string
    description: string
    category: string
  }
  checkout_note_img: string
  is_full: boolean
  is_late: boolean
  is_early: boolean
  is_alpha: boolean
  is_leave: boolean
  duration: number
  overtime: number
  rotation_location_id: number
  rotation_location_name: string
  image_url: string
  leave_notes: string
  location_id: number
  location_name: string
  approvals: ApprovalInterface[]
  emergency_rotation: AttendanceInterface[]
}

export interface ScheduleInterface {
  schedule_id: number
  description: ShiftNameType
  start_date: string
  start_time: string
  end_date: string
  end_time: string
  shift_name: ShiftUnionType
  overtime: number
  active: boolean
  note: string
  role: string
  attachment: string
  location_id: number
  location_name: string
  rotation_location_id: number
  rotation_location_name: string
  is_checkin: boolean
  approvals: ApprovalInterface[]
  emergency_rotation: {
    active: boolean
    attachment: string
    description: string
    emergency_rotation: null
    end_date: string
    end_time: string
    is_checkin: boolean
    note: string
    overtime: number
    role: string
    location_id: number
    location_name: string
    location_type: string
    rotation_location_id: number
    rotation_location_name: string
    rotation_schedule_id: number
    schedule_id: number
    shift_name: string
    start_date: string
    start_time: string
    approvals: ApprovalInterface[]
  }[]
}

export interface GetScheduleListAPIInterface {
  data: {
    staff_id: number
    full_name: string
    location_id: number
    location_name: string
    role: string
    is_rotation_staff: boolean
    schedule: ScheduleInterface[]
  }[]
}

export interface GetAttendanceListServiceInterface {
  data: {
    staff_id: number
    full_name: string
    location_id: number
    location_name: string
    role: string
    attendance: AttendanceInterface[]
  }[]
}

export interface GetSummaryScheduleServicesInterface {
  data: {
    description: string
    end_time: string
    shift_name: string
    staff_total: number
    start_time: string
    schedule_shift_detail: {
      date: string
      count: number
    }[]
  }[]
}

export interface GetStaffDetailAPIInterface {
  data: {
    supervisor: string
    driver_id: number
    full_name: string
    location_id: 1
    location_name: string
    role: string
    phone_number: string
    date_join: Date
    active: boolean
  }
}

export interface GetStaffDetailAttendanceServiceInterface {
  data: {
    id: number
    staff_id: number
    location: {
      id: number
      name: string
    }
    checkin_date: string
    precheckin_time: string
    checkin_time: string
    checkin_img: string
    checkin_note?: {
      code: string
      description: string
    }
    checkout_date: string
    precheckout_time: string
    checkout_time: string
    checkout_img: string
    checkout_note?: {
      code: string
      description: string
    }
    is_full: boolean
    is_late: boolean
    is_early: boolean
    duration: number
    durationStr: string
    overtime: number
    role: string
    emergency_rotation: AttendanceInterface[]
  }
}

export interface GetAttendanceHistoryServiceInterface {
  data: {
    id: number
    attendance_id: number
    staff_id: number
    location_id: number
    checkin_date: string
    email: string
    update: string
    changes_date: string
    changes_time: string
    notes: string
  }[]
}

export interface GetShiftListAPIInterface {
  data: {
    location_id: number
    shift_name: ShiftUnionType
    description: string
    start_time: string
    end_time: string
    is_normal_shift: boolean
    is_note_required: boolean
    is_attachment_required: boolean
    is_custom_time: boolean
  }[]
}

export interface GetAttendanceNotesInterface {
  data: {
    code: string
    description: string
  }[]
}

export type PostLeaverPermissionCurrentDayType = {
  day_permit_file_url: string
  approval_ids: number[]
  location_id: number
  notes: string
  schedule_id: number
  staff_id: number
  created_by: string
  roles: string[]
}

export type UpdateSchedulePayloadType = {
  location_id: number
  start_date: string
  start_time: string
  end_date: string
  end_time: string
  shift_name: string
  staff_id: number
  over_time: string
  updated_by: string
  note: string
  description: string
  rotation_location_id: number
  role: string
  is_admin: boolean
  authority: string[]
  rotation_schedule_id: number
  schedule_file_url: string
  approval_ids?: number[]
}

export type CreateSchedulePayloadType = {
  schedule_file_url: string
  approval_ids?: number[]
  authority: string[]
  created_by: string
  description: string
  end_date: string
  end_time: string
  file_name: string
  is_admin: boolean
  location_id: number
  note: string
  over_time: string
  role: string
  rotation_location_id: number
  rotation_schedule_id: number
  shift_name: string
  staff_id: number
  start_date: string
  start_time: string
}

export type UpdateAttendancePayloadType = {
  staff_id: number
  location_id: number
  checkin_date?: string
  checkin_time?: string
  checkin_note?: string
  checkout_time?: string
  overtime: number
  checkin_time_change_notes?: string
  checkin_note_change_notes?: string
  checkout_time_change_notes?: string
  overtime_change_notes?: string
  updated_by: string
  roles: string[]
  approval_ids?: number[]
  checkin_file_url?: string
  checkout_file_url?: string
  checkin_note_file_url?: string
  checkout_note_file_url?: string
}

export const getScheduleListAPI = (params: {
  startDate?: string
  endDate?: string
  locationId?: number
  search?: string
  department?: string
}) =>
  axios.get<GetScheduleListAPIInterface>(`${baseUrl}/api/staff-management/schedule/list`, {
    params,
  })

export const getScheduleSummaryServices = (params: {
  startDate?: string
  endDate?: string
  locationId?: number
  search?: string
}) =>
  axios.get<GetSummaryScheduleServicesInterface>(
    `${baseUrl}/api/staff-management/schedule/summary`,
    {
      params,
    },
  )

export const getStaffDetailAPI = (params: { id: number }) =>
  axios.get<GetStaffDetailAPIInterface>(`${baseUrl}/api/staff-management/schedule/staff-detail`, {
    params,
  })

export const getAttendanceListService = (params: {
  startDate?: string
  endDate?: string
  locationId?: number
  search?: string
  department?: string
}) =>
  axios.get<GetAttendanceListServiceInterface>(`${baseUrl}/api/staff-management/attendance/list`, {
    params,
  })

export const getStaffDetailAttendanceService = ({
  id,
  date,
  locationId,
}: {
  id: number
  date: string
  locationId: number
}) =>
  axios.get<GetStaffDetailAttendanceServiceInterface>(
    `${baseUrl}/api/staff-management/attendance/${id}/${date}/${locationId}`,
  )

export const getAttendanceHistoryService = (params: {
  staff_id: number
  checkin_date: string
  location_id: number
}) =>
  axios.get<GetAttendanceHistoryServiceInterface>(
    `${baseUrl}/api/staff-management/attendance/history`,
    { params },
  )

const createFormData = (payload: Record<string, unknown>) => {
  const formData = new FormData()
  Object.entries(payload).forEach((each) => {
    const [key, value] = each
    if (value || value === 0) {
      formData.append(key, value as string | Blob)
    }
  })
  return formData
}

export const getExportedSchedule = (data: {
  month: string
  locationId: number
  year: string
  department: string
}) =>
  AxiosInstance.Post<{ file_url: 'string' }>({
    url: '/staff-management/internal/:version/schedule/export',
    version: 'v1',
    data,
    convertRequest: true,
  })

export const getExportedAttendance = (params: {
  startDate: string
  endDate: string
  locationId: number
  department: string
}) =>
  axios.get<{ data: { file_url: 'string' } }>(`${baseUrl}/api/staff-management/attendance/export`, {
    params,
  })

export const deleteLeavePermission = (data: { schedule_id: string }) => {
  const formData = createFormData(data)
  axios.delete(`${baseUrl}/api/staff-management/attendance/day-permit`, { data: formData })
}

export const deleteSchedule = (id: number) =>
  axios.delete(`${baseUrl}/api/staff-management/schedule/delete?id=${id}`)

export const postCreateScheduleAPI = (data: CreateSchedulePayloadType) =>
  AxiosInstance.Post({
    url: '/staff-management/internal/v2/schedule',
    data,
  })

export const putUpdateScheduleAPI = (data: UpdateSchedulePayloadType, scheduleId: number) =>
  AxiosInstance.Put({
    url: `/staff-management/internal/v2/schedule/${scheduleId}`,
    data,
  })

export const putUpdateAttendanceAPI = (data: UpdateAttendancePayloadType, id: number) =>
  AxiosInstance.Put({
    url: `/staff-management/internal/v2/attendance/${id}`,
    data,
  })

export const postLeavePermissionCurrentDay = (data: PostLeaverPermissionCurrentDayType) =>
  AxiosInstance.Post({
    url: '/staff-management/internal/v2/attendance/day-permit',
    data,
  })

export const getAttendanceNotesService = () =>
  AxiosInstance.Get<GetAttendanceNotesInterface, true>({
    url: '/staff-management/internal/:version/attendance/notes',
    version: 'v1',
  })

export const getShiftListAPI = (params: { locationId: string }) =>
  AxiosInstance.Get<GetShiftListAPIInterface, true>({
    url: '/staff-management/internal/:version/shift/list',
    version: 'v1',
    params: {
      location_id: params.locationId,
    },
  })

export const getHRISimageRules = (typeId: ImageVariantIdType) => ServiceInternalImageRules(typeId)

export const postHRISImage = (payload: ImageUploadPayloadType[]) =>
  ServiceInternalImageUpload(payload)
