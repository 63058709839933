import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
  InitialStateType,
  DialogProductListStateType,
  PopupModeType,
  ProductOptionType,
  SetPopupQueryProductOptionPayloadActionType,
  SetPopupFormPinProductPayloadActionType,
  PinProductType,
  PinProductPaginationType,
  PinProductQueryType,
  PinProductTypeType,
  CategoryOptionType,
  SetConstantPayloadType,
  SetPopupFormPinProductItemsPayloadActionType,
} from 'features/CategoryAndPosisi/@types/PinProduct'

export const SLICE_NAME_PIN_PRODUCT = 'pinProduct'

export const initialState: InitialStateType = {
  isLoading: [],
  dialogProductListState: 'CLOSE',
  popup: {
    mode: '',
    productOptions: [],
    selectedProduct: [],
    queryProductOption: { isActive: true, name: '', pageSize: 20 },
    formPinProduct: {
      created_at: '',
      created_by: '',
      end_date: '',
      end_time: '',
      start_date: '',
      start_time: '',
      is_active: false,
      id: 0,
      notes: '',
      ref_id: 0,
      ref_value: '',
      status: '',
      type_id: 0,
      type_name: '',
      updated_at: '',
      updated_by: '',
      items: [],
    },
    typeOptions: [],
    cetegoryOptions: [],
  },
  pinProductList: [],
  pagination: {},
  query: {
    previous_cursor: 0,
    next_cursor: 0,
    page_size: 20,
    filter_start_date: '',
    filter_end_date: '',
    filter_status: '',
    filter_type: '',
  },
  constants: {
    pinProductTypeOptions: [],
    pinProductStatusOptions: [],
  },
}

const slice = createSlice({
  name: SLICE_NAME_PIN_PRODUCT,
  initialState,

  reducers: {
    resetState: () => initialState,
    resetPopup: (state) => {
      state.popup = initialState.popup
    },
    resetPopupForm: (state) => {
      state.popup.formPinProduct = initialState.popup.formPinProduct
    },
    resetPopupSelectedProduct: (state) => {
      state.popup.selectedProduct = initialState.popup.selectedProduct
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      if (payload) {
        state.isLoading.push(true)
      } else {
        state.isLoading.pop()
      }
    },
    setQuery: (state, { payload }: PayloadAction<PinProductQueryType>) => {
      state.query = { ...payload }
    },
    setDialogProductListState: (state, { payload }: PayloadAction<DialogProductListStateType>) => {
      state.dialogProductListState = payload
    },
    setPopupMode: (state, { payload }: PayloadAction<PopupModeType>) => {
      state.popup.mode = payload
    },
    setPopupProductList: (state, { payload }: PayloadAction<ProductOptionType[]>) => {
      state.popup.productOptions = payload
    },
    addPopupSelectedProduct: (state, { payload }: PayloadAction<ProductOptionType>) => {
      state.popup.selectedProduct = [...state.popup.selectedProduct, payload]
    },
    removePopupSelectedProduct: (state, { payload }: PayloadAction<ProductOptionType>) => {
      state.popup.selectedProduct = state.popup.selectedProduct.filter(
        (el) =>
          el.productID !== payload.productID &&
          el.productName !== payload.productName &&
          el.productSkuNo !== payload.productSkuNo,
      )
    },
    setPopupCheckedProduct: (
      state,
      { payload }: PayloadAction<{ index: number; checked: boolean; data: ProductOptionType }>,
    ) => {
      const { checked, data } = payload

      state.popup.productOptions = state.popup.productOptions.map((el) => {
        const isSelectedData =
          el.productID === data.productID &&
          el.productName === data.productName &&
          el.productSkuNo === data.productSkuNo

        return { ...el, checked: isSelectedData ? checked : el.checked }
      })
    },
    setPopupPinProductTypeOptions: (state, { payload }: PayloadAction<PinProductTypeType[]>) => {
      state.popup.typeOptions = payload
    },
    setPopupQueryProductOption: (
      state,
      { payload }: PayloadAction<SetPopupQueryProductOptionPayloadActionType>,
    ) => {
      state.popup.queryProductOption = { ...state.popup.queryProductOption, ...payload.value }
    },
    setPopupFormPinProduct: (
      state,
      { payload }: PayloadAction<SetPopupFormPinProductPayloadActionType>,
    ) => {
      state.popup.formPinProduct = { ...state.popup.formPinProduct, ...payload.value }
    },
    setPopupFormPinProductItems: (
      state,
      { payload }: PayloadAction<SetPopupFormPinProductItemsPayloadActionType>,
    ) => {
      state.popup.formPinProduct.items = payload.value
    },
    setPopupCategoryOptions: (state, { payload }: PayloadAction<CategoryOptionType[]>) => {
      state.popup.cetegoryOptions = payload
    },
    setPinProductList: (state, { payload }: PayloadAction<PinProductType[]>) => {
      state.pinProductList = [...payload]
    },
    setPinProductPagination: (state, { payload }: PayloadAction<PinProductPaginationType>) => {
      state.pagination = { ...payload }
    },
    setConstant: (state, { payload }: PayloadAction<SetConstantPayloadType>) => {
      const { field, value } = payload
      state.constants[field] = value as never
    },
  },
})

export const {
  resetState,
  setIsLoading,
  setDialogProductListState,
  setPopupMode,
  setPopupProductList,
  setPopupQueryProductOption,
  setPopupFormPinProduct,
  setQuery,
  setPinProductList,
  setPinProductPagination,
  addPopupSelectedProduct,
  removePopupSelectedProduct,
  setPopupCheckedProduct,
  resetPopup,
  setPopupPinProductTypeOptions,
  resetPopupSelectedProduct,
  setPopupCategoryOptions,
  setConstant,
  setPopupFormPinProductItems,
  resetPopupForm,
} = slice.actions
export default slice.reducer
