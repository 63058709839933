import { createAsyncThunk } from '@reduxjs/toolkit'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { getLocationDetail } from 'features/Location/services/location'
import { getOrderLocations } from 'features/Order/services/allOrder'
import {
  getDeliveryPoolCalculateClusters,
  postDeliveryPoolCalculate,
} from 'features/Order/services/poolingMap'
import { callErrorMsg } from 'helpers/errorMsg'
import { RootStateType } from 'store'
import { tagPoolDataAPI, unTagPoolDataAPI } from 'utils/apiList/orderPool'
import { toastSuccess } from 'utils/toast'

export const SLICE_NAME = 'poolingMap'

export const fetchGetListLocationPooling = createAsyncThunk(
  `${SLICE_NAME}/fetchGetListLocationPooling`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getOrderLocations()

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchLocationDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchLocationDetail`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await getLocationDetail(id)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchOrderList = createAsyncThunk(
  `${SLICE_NAME}/fetchOrderList`,
  async (payload: { id: number; withLoading?: boolean }, { rejectWithValue }) => {
    try {
      const { id } = payload
      const response = await getDeliveryPoolCalculateClusters(id)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const getDataDeliveryPool = createAsyncThunk(
  `${SLICE_NAME}/getDataDeliveryPool`,
  async (payload: { location_id?: number; order_ids: number[] }, { rejectWithValue }) => {
    try {
      const response = await postDeliveryPoolCalculate(payload)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const tagPoolDataV2 = createAsyncThunk(
  `${SLICE_NAME}/tagPoolDataV2`,
  async (
    payload: {
      email: string
      password: string
      order_ids_pool: { pool_number: number; orders: number[]; formatted_pool_number: string }
      order_ids_unchecked: {
        pool_number: number
        orders: number[]
        is_main_pool: boolean
        formatted_pool_number: string
      }[]
      formatted_pool_number: string
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const response = await tagPoolDataAPI(payload)
      const { locationSelected } = (getState() as RootStateType).poolingMap
      toastSuccess('Pesanan berhasil di pool')
      dispatch(fetchOrderList({ id: locationSelected?.location_id || 0, withLoading: true }))
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
export const untagPoolDataV2 = createAsyncThunk(
  `${SLICE_NAME}/untagPoolDataV2`,
  async (
    payload: {
      email: string
      password: string
      data: {
        pool_number: number
        order_ids_pool: number[]
        order_ids_unpool: number[]
        formatted_pool_number: string
      }[]
      reason: string
      reason_id: number
      formatted_pool_number: string
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const response = await unTagPoolDataAPI(payload)
      const { locationSelected } = (getState() as RootStateType).poolingMap
      toastSuccess('Pesanan berhasil batal kirim bersamaan')
      dispatch(fetchOrderList({ id: locationSelected?.location_id || 0, withLoading: true }))
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
