import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  type ItemSummaryType,
  type RouteSummaryType,
  type ListDeliveriesType,
  DeliveriesType,
} from 'features/Order/pages/PoolingMap/types'
import {
  SLICE_NAME,
  fetchGetListLocationPooling,
  fetchOrderList,
  getDataDeliveryPool,
  tagPoolDataV2,
  untagPoolDataV2,
} from './thunk'

export type OrderPoolingMapSliceType = {
  isLoading: boolean
  isLoadingOverlay: boolean
  isLoadingTag: boolean
  isViewingOrderList: boolean
  listLocation: {
    location_id: number
    location_name: string
    location_type: string
  }[]
  locationSelected: Nullable<{
    location_id: number
    location_name: string
    location_type: string
    location_latitude: string
    location_longitude: string
  }>
  itemSummary: ItemSummaryType
  routeSummary: RouteSummaryType
  clusters: ListDeliveriesType
  selectedDeliverySummary: ListDeliveriesType['delivery_pools'][number]
  removedDelivery: DeliveriesType
}

export const initialState: OrderPoolingMapSliceType = {
  isLoading: false,
  isLoadingOverlay: false,
  isLoadingTag: false,
  isViewingOrderList: false,
  listLocation: [],
  locationSelected: null,
  itemSummary: {
    total_qty: 0,
    total_sku: 0,
    total_weight: 0,
    total_volume: 0,
    total_distance: 0,
  },
  routeSummary: {
    shapes: [],
    total_distance: 0,
  },
  clusters: {
    location_id: 0,
    delivery_pools: [],
  },
  selectedDeliverySummary: {
    pool_id: 0,
    pool_number: '0',
    summary: {
      items_summary: {
        total_qty: 0,
        total_sku: 0,
        total_weight: 0,
        total_volume: 0,
        total_distance: 0,
      },
      route_summary: {
        shapes: [],
        total_distance: 0,
      },
    },
    deliveries: [],
  },
  removedDelivery: [],
}

const resetPoolingState = (state: OrderPoolingMapSliceType) => {
  state.isLoadingTag = false
  state.isViewingOrderList = false
  state.selectedDeliverySummary = initialState.selectedDeliverySummary
  state.clusters = {
    ...initialState.clusters,
    location_id: state.clusters.location_id,
  }
  state.removedDelivery = []
  state.itemSummary = initialState.itemSummary
  state.routeSummary = initialState.routeSummary
}

const orderPoolingMapSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsViewingOrderList: (
      state,
      action: PayloadAction<OrderPoolingMapSliceType['isViewingOrderList']>,
    ) => {
      state.isViewingOrderList = action.payload
    },
    setLocationSelected: (
      state,
      action: PayloadAction<OrderPoolingMapSliceType['locationSelected']>,
    ) => {
      state.locationSelected = action.payload
    },
    setClusters: (state, action: PayloadAction<OrderPoolingMapSliceType['clusters']>) => {
      state.clusters = action.payload
    },
    setSummary: (
      state,
      action: PayloadAction<{
        items_summary: OrderPoolingMapSliceType['itemSummary']
        route_summary: OrderPoolingMapSliceType['routeSummary']
      }>,
    ) => {
      state.itemSummary = action.payload.items_summary
      state.routeSummary = action.payload.route_summary
    },
    setDeliveries: (
      state,
      action: PayloadAction<OrderPoolingMapSliceType['selectedDeliverySummary']>,
    ) => {
      state.selectedDeliverySummary = action.payload
    },
    setRemoveDelivery: (
      state,
      action: PayloadAction<{ order_id: number; pool_number: string }>,
    ) => {
      const { order_id, pool_number } = action.payload
      if (pool_number !== '0') {
        const removedDelivery = state.selectedDeliverySummary.deliveries.find(
          (delivery) => delivery.order_id === order_id,
        )
        if (removedDelivery) {
          state.removedDelivery.push(removedDelivery)
        }
      }

      state.selectedDeliverySummary.deliveries = state.selectedDeliverySummary.deliveries.filter(
        (delivery) => delivery.order_id !== order_id,
      )
    },
    setResetOrderList: (state) => {
      state.selectedDeliverySummary = initialState.selectedDeliverySummary
      state.removedDelivery = initialState.removedDelivery
      state.isViewingOrderList = false
    },
    setIsLoadingTag: (state, action: PayloadAction<OrderPoolingMapSliceType['isLoadingTag']>) => {
      state.isLoadingTag = action.payload
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<OrderPoolingMapSliceType>) => {
    builder
      .addCase(fetchGetListLocationPooling.fulfilled, (state, action) => {
        state.listLocation = action.payload.data
      })
      .addCase(getDataDeliveryPool.fulfilled, (state, action) => {
        const { items_summary, route_summary } = action.payload.data.summary
        state.itemSummary = items_summary
        state.routeSummary = route_summary
      })
      .addCase(fetchOrderList.pending, (state, action) => {
        if (action.meta.arg.withLoading) {
          state.isLoadingOverlay = true
        }
      })
      .addCase(fetchOrderList.rejected, (state) => {
        state.isLoadingOverlay = false
      })
      .addCase(fetchOrderList.fulfilled, (state, action) => {
        state.isLoadingOverlay = false
        // Assign unique colors to each pool based on pool_id
        const colorMap = new Map()
        const colors = [
          '#8B0000', // Dark Red
          '#006400', // Dark Green
          '#00008B', // Dark Blue
          '#8B008B', // Dark Magenta
          '#008B8B', // Dark Cyan
          '#8B8B00', // Dark Yellow
          '#8B4500', // Dark Orange
          '#4B0082',
        ]

        action.payload.data.delivery_pools.forEach(
          (pool: { pool_number: string }, index: number) => {
            if (!colorMap.has(pool.pool_number)) {
              if (pool.pool_number === '0') {
                colorMap.set(pool.pool_number, '#808080') // Grey color for pool_number '0'
              } else {
                colorMap.set(pool.pool_number, colors[index % colors.length])
              }
            }
          },
        )

        // Add color property to each delivery pool
        state.clusters = {
          ...action.payload.data,
          delivery_pools: action.payload.data.delivery_pools.map(
            (pool: {
              pool_id: number
              pool_number: string
              summary: {
                items_summary: ItemSummaryType
                route_summary: RouteSummaryType
              }
              deliveries: DeliveriesType
              color?: string
            }) => ({
              ...pool,
              color: colorMap.get(pool.pool_number),
            }),
          ),
        }
      })
      .addCase(tagPoolDataV2.pending, (state) => {
        state.isLoadingTag = true
      })
      .addCase(tagPoolDataV2.fulfilled, (state) => {
        resetPoolingState(state)
      })
      .addCase(tagPoolDataV2.rejected, (state) => {
        state.isLoadingTag = false
      })
      .addCase(untagPoolDataV2.pending, (state) => {
        state.isLoadingTag = true
      })
      .addCase(untagPoolDataV2.fulfilled, (state) => {
        resetPoolingState(state)
      })
      .addCase(untagPoolDataV2.rejected, (state) => {
        state.isLoadingTag = false
      })
  },
})

export const {
  setLocationSelected,
  setClusters,
  setSummary,
  setDeliveries,
  setIsViewingOrderList,
  setResetOrderList,
  setRemoveDelivery,
  setIsLoadingTag,
} = orderPoolingMapSlice.actions
export default orderPoolingMapSlice.reducer
