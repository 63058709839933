import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import {
  type ItemSummaryType,
  type RouteSummaryType,
  type ListDeliveriesType,
} from '../pages/PoolingMap/types'

const { Post, Get } = axiosInstanceApiGateway

type DeliveryPoolCalculateResponseType = {
  summary: {
    items_summary: ItemSummaryType
    route_summary: RouteSummaryType
  }
}

export type DeliveryPoolCalculatePayloadType = {
  location_id?: number
  order_ids: number[]
}

export const postDeliveryPoolCalculate = (payload: DeliveryPoolCalculatePayloadType) =>
  Post<DeliveryPoolCalculateResponseType>({
    url: '/logistic/internal/v1/delivery-pools/calculate',
    data: payload,
  })

export const getDeliveryPoolCalculateClusters = (location_id: number) =>
  Get<ListDeliveriesType>({
    url: `/logistic/internal/v1/delivery-pools`,
    params: {
      location_id,
    },
  })
