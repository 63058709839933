import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getPinProducts,
  getPinProductType,
  getPinProductDetail,
  putUpdatePinProduct,
  postPinProduct,
  putPinProductStatus,
  getPinProductFilterAttribute,
  getProductV2API,
  getPINProductSimilar,
  type GetPinProductDetailResponseType,
  type PutUpdatePinProductRequestType,
  type PostPinProductRequestType,
} from 'features/CategoryAndPosisi/services/pinProduct'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  PinProductQueryType,
  FormPinProductType,
  UpdatePinProductStatusPayloadType,
  FilterDropdownOptionType,
} from 'features/CategoryAndPosisi/@types/PinProduct'
import { dateFormat } from 'utils/helpers/date'
import { PIN_PRODUCT_TYPE } from 'features/CategoryAndPosisi/constants/pinProduct'
import { getCategories } from 'features/BannerAndTicker/sevices/bannerThematic'
import {
  SLICE_NAME_PIN_PRODUCT,
  setPinProductList,
  setPinProductPagination,
  setIsLoading,
  setPopupPinProductTypeOptions,
  setPopupFormPinProduct,
  setPopupProductList,
  setPopupCategoryOptions,
  setConstant,
  setPopupFormPinProductItems,
} from './slice'

const PAYLOAD_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const getCleanQuery = (query: PinProductQueryType): Partial<PinProductQueryType> =>
  Object.keys(query).reduce<Partial<PinProductQueryType>>((acc, key) => {
    if (query[key as keyof typeof query]) {
      return { ...acc, [key]: query[key as keyof typeof query] }
    }
    return acc
  }, {})

export const fetchPinProductList = createAsyncThunk(
  `${SLICE_NAME_PIN_PRODUCT}/fetchPinProductList`,
  async (_, { dispatch, getState }) => {
    const {
      pinProduct: { query },
    } = getState() as StoreStateType

    dispatch(setIsLoading(true))
    try {
      const {
        data: { data, pagination },
      } = await getPinProducts(getCleanQuery(query))

      dispatch(setPinProductList(data))
      dispatch(setPinProductPagination(pagination))
    } catch (error) {
      callErrorMsg(error)
    }
    dispatch(setIsLoading(false))
  },
)

export const fetchPinProductType = createAsyncThunk(
  `${SLICE_NAME_PIN_PRODUCT}/fetchPinProductType`,
  async (_, { dispatch }) => {
    dispatch(setIsLoading(true))
    try {
      const { data } = await getPinProductType()

      dispatch(setPopupPinProductTypeOptions(data.data.types))
    } catch (error) {
      callErrorMsg(error)
    }
    dispatch(setIsLoading(false))
  },
)

export const fetchProductList = createAsyncThunk(
  `${SLICE_NAME_PIN_PRODUCT}/fetchProductList`,
  async (_, { rejectWithValue, dispatch, getState }) => {
    const {
      pinProduct: {
        popup: { queryProductOption, formPinProduct, selectedProduct },
      },
    } = getState() as StoreStateType
    dispatch(setIsLoading(true))
    try {
      dispatch(setIsLoading(false))

      const res = await getProductV2API({
        name: queryProductOption.name,
        categoryIDs:
          PIN_PRODUCT_TYPE.CATEGORY === formPinProduct.type_id ? formPinProduct.ref_id : undefined,
        pageSize: queryProductOption.pageSize,
        isActive: queryProductOption.isActive,
      })

      dispatch(
        setPopupProductList(
          res.data.data.map((el) => {
            const haveProductSelected = selectedProduct.some(
              (elSome) =>
                el.productID === elSome.productID &&
                el.productName === elSome.productName &&
                el.productSkuNo === elSome.productSkuNo,
            )

            return {
              active: el.active,
              images: el.images.length
                ? el.images[0]
                : { imageURLLarge: '', imageURLMedium: '', imageURLSmall: '' },
              productID: el.productID,
              productName: el.productName,
              productSkuNo: el.productSkuNo,
              name: el.productName,
              checked: haveProductSelected,
            }
          }),
        ),
      )
      return ''
    } catch (err) {
      callErrorMsg(err)
      dispatch(setIsLoading(false))

      return rejectWithValue(err)
    }
  },
)

const composeFormPinProduct: (
  data: GetPinProductDetailResponseType['data'],
) => FormPinProductType = (data) => {
  const { items, master_pin_product } = data

  return {
    created_at: master_pin_product.created_at,
    created_by: master_pin_product.created_by,
    end_date: dateFormat({ date: new Date(master_pin_product.end_date), format: 'YYYY-MM-DD' }),
    end_time: dateFormat({ date: new Date(master_pin_product.end_date), format: 'HH:mm' }),
    start_date: dateFormat({ date: new Date(master_pin_product.start_date), format: 'YYYY-MM-DD' }),
    start_time: dateFormat({ date: new Date(master_pin_product.start_date), format: 'HH:mm' }),
    id: master_pin_product.id,
    is_active: master_pin_product.is_active,
    items: items.map((el) => ({ ...el, product_sku: el.sku })),
    notes: master_pin_product.notes,
    ref_id: master_pin_product.ref_id ? master_pin_product.ref_id : 0,
    ref_value: master_pin_product.ref_value,
    status: master_pin_product.status,
    type_id: master_pin_product.type_id ? master_pin_product.type_id : 0,
    type_name: master_pin_product.type_name,
    updated_at: master_pin_product.updated_at,
    updated_by: master_pin_product.updated_by,
  }
}

export const fetchPinProductDetail = createAsyncThunk(
  `${SLICE_NAME_PIN_PRODUCT}/fetchPinProductDetail`,
  async ({ id }: { id: string }, { dispatch }) => {
    dispatch(setIsLoading(true))
    try {
      const { data } = await getPinProductDetail({ id })

      dispatch(setPopupFormPinProduct({ value: composeFormPinProduct(data.data) }))
    } catch (error) {
      callErrorMsg(error)
    }
    dispatch(setIsLoading(false))
  },
)

const composePayloadUpdatePinProduct: (
  form: FormPinProductType,
  userId: number,
) => PutUpdatePinProductRequestType['payload'] = (form, userId) => ({
  id: form.id,
  items: form.items.map((el, i) => ({ product_id: el.product_id, sequences: i + 1 })),
  end_date: dateFormat({
    date: new Date(`${form.end_date} ${form.end_time}`),
    format: PAYLOAD_DATE_FORMAT,
  }),
  start_date: dateFormat({
    date: new Date(`${form.start_date} ${form.start_time}`),
    format: PAYLOAD_DATE_FORMAT,
  }),
  is_active: form.is_active,
  ref_id: form.ref_id,
  notes: form.notes,
  ref_value: form.ref_value,
  type_id: form.type_id,
  status: form.status,
  user_id: userId,
})

export const updatePinProduct = createAsyncThunk(
  `${SLICE_NAME_PIN_PRODUCT}/updatePinProduct`,
  async (_, { dispatch, getState, rejectWithValue }) => {
    dispatch(setIsLoading(true))

    const {
      pinProduct: {
        popup: { formPinProduct },
      },
      auth: {
        userData: { id: userId },
      },
    } = getState() as StoreStateType

    try {
      const { data } = await putUpdatePinProduct({
        payload: composePayloadUpdatePinProduct(formPinProduct, userId),
        id: formPinProduct.id.toString(),
      })
      dispatch(setIsLoading(false))
      return data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsLoading(false))
      return rejectWithValue(error)
    }
  },
)

export const fetchCategoryList = createAsyncThunk(
  `${SLICE_NAME_PIN_PRODUCT}/fetchCategoryList`,
  async (_, { dispatch }) => {
    dispatch(setIsLoading(true))

    try {
      const { data } = await getCategories()

      dispatch(
        setPopupCategoryOptions(data.map((el) => ({ id: el.category_id, name: el.category_name }))),
      )
    } catch (error) {
      callErrorMsg(error)
    }
    dispatch(setIsLoading(false))
  },
)

const composePayloadCreatePinProduct: (
  form: FormPinProductType,
) => PostPinProductRequestType['payload'] = (form) => ({
  type_id: form.type_id,
  ref_value: form.ref_value,
  ref_id: form.ref_id,
  end_date: dateFormat({
    date: new Date(`${form.end_date} ${form.end_time}`),
    format: PAYLOAD_DATE_FORMAT,
  }),
  start_date: dateFormat({
    date: new Date(`${form.start_date} ${form.start_time}`),
    format: PAYLOAD_DATE_FORMAT,
  }),
  items: form.items.map((el, i) => ({ product_id: el.product_id, sequences: i + 1 })),
  notes: form.notes,
})

export const createPinProduct = createAsyncThunk(
  `${SLICE_NAME_PIN_PRODUCT}/createPinProduct`,
  async (_, { dispatch, getState, rejectWithValue }) => {
    dispatch(setIsLoading(true))
    const {
      pinProduct: {
        popup: { formPinProduct },
      },
      auth: {
        userData: { id: userId },
      },
    } = getState() as StoreStateType
    try {
      const { data } = await postPinProduct({
        payload: composePayloadCreatePinProduct(formPinProduct),
        user_id: userId,
      })
      dispatch(setIsLoading(false))

      return data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsLoading(false))

      return rejectWithValue(error)
    }
  },
)

export const updatePinProductStatus = createAsyncThunk(
  `${SLICE_NAME_PIN_PRODUCT}/updatePinProductStatus`,
  async (
    payload: UpdatePinProductStatusPayloadType & Partial<{ id: number }>,
    { dispatch, getState },
  ) => {
    dispatch(setIsLoading(true))

    const {
      pinProduct: {
        popup: { formPinProduct },
      },
    } = getState() as StoreStateType

    try {
      await putPinProductStatus({
        id: formPinProduct.id.toString(),
        payload: {
          id: payload.id || formPinProduct.id,
          action: payload.action || 0,
          notes: payload.notes || '',
          state: payload.state || null,
        },
      })
    } catch (error) {
      callErrorMsg(error)
    }
    dispatch(setIsLoading(false))
  },
)

export const fetchPinProductFilterAttribute = createAsyncThunk(
  `${SLICE_NAME_PIN_PRODUCT}/fetchPinProductFilterAttribute`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getPinProductFilterAttribute()

      let typeOptions: FilterDropdownOptionType[] = []
      let statusOptions: FilterDropdownOptionType[] = []

      data.data.filters.forEach((filter) => {
        if (/type/gi.test(filter.filter_label)) {
          typeOptions = [...filter.components]
        }

        if (/status/gi.test(filter.filter_label)) {
          statusOptions = [...filter.components]
        }
      })

      dispatch(setConstant({ field: 'pinProductTypeOptions', value: typeOptions }))
      dispatch(setConstant({ field: 'pinProductStatusOptions', value: statusOptions }))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchPINProductSimilar = createAsyncThunk(
  `${SLICE_NAME_PIN_PRODUCT}/fetchPINProductSimilar`,
  async (_, { rejectWithValue, getState, dispatch }) => {
    dispatch(setIsLoading(true))
    const {
      pinProduct: {
        popup: { formPinProduct },
      },
    } = getState() as StoreStateType
    try {
      const { data } = await getPINProductSimilar({
        params: {
          ref_value: formPinProduct.ref_value,
          type_id: formPinProduct.type_id,
          start_date: dateFormat({
            date: new Date(`${formPinProduct.start_date} ${formPinProduct.start_time}`),
            format: PAYLOAD_DATE_FORMAT,
          }),
        },
      })

      const { items } = formPinProduct

      if (items.length > 0) {
        const newItems = [
          ...data.data.items
            .filter(
              (elFilter) =>
                !items.some(
                  (elSome) =>
                    elSome.product_id === elFilter.product_id &&
                    elSome.product_sku === elFilter.sku,
                ),
            )
            .map((el) => ({ ...el, product_sku: el.sku })),
          ...items,
        ]

        dispatch(
          setPopupFormPinProductItems({
            value: newItems,
          }),
        )
      } else {
        dispatch(
          setPopupFormPinProductItems({
            value: data.data.items.map((el) => ({ ...el, product_sku: el.sku })),
          }),
        )
      }

      dispatch(setIsLoading(false))
      return data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsLoading(false))
      return rejectWithValue(error)
    }
  },
)
