import { lazy } from 'react'
import { roles } from 'constant/roles'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const ReplenishmentTask = lazy(() => import('../pages/ReplenishmentTask'))
const ReplenishmentTaskDetail = lazy(() => import('../pages/ReplenishmentTaskDetail'))
const ReplenishmentTaskPartial = lazy(() => import('../pages/ReplenishmentTaskPartial'))
const ReplenishmentSchedule = lazy(() => import('../pages/ReplenishmentSchedule'))
const InboundUnloadingDetail = lazy(() => import('../pages/InboundUnloadingDetail'))
const LimitSKUPage = lazy(() => import('../pages/LimitSKU'))

type NamedRoutesType =
  | 'ReplenishmentTaskRouter'
  | 'ReplenishmentTaskDetailRouter'
  | 'ReplenishmentTaskPartialRouter'
  | 'ReplenishmentScheduleRouter'
  | 'InboundUnloadingDetailRouter'
  | 'LimitSKURouter'

type PathListsType =
  | '/dashboard/replenishment-task'
  | '/dashboard/replenishment-task/:id'
  | '/dashboard/replenishment-task/partial/:id'
  | '/dashboard/replenishment-schedule'
  | '/dashboard/inbound-so/unloading/:id'
  | '/dashboard/limit-sku'

type RouterInstanceType = RouterFeatureInterface<NamedRoutesType, PathListsType>

const ReplenishmentTaskRouter: RouterInstanceType = {
  name: 'ReplenishmentTaskRouter',
  Component: ReplenishmentTask,
  path: '/dashboard/replenishment-task',
  routeType: 'PRIVATE',

  allowedRoles: [
    'AUTH_SHIFT_LEAD',
    'AUTH_HUB_INV_SPV',
    'AUTH_CENTRAL_SUPERVISOR',
    'AUTH_OUTBOUND_STAFF',
    'AUTH_INVENTORY_CONTROLLER',
    'AUTH_LP',
    'AUTH_SUPPLY_CHAIN',
  ],
  isDashboard: true,
}

const ReplenishmentTaskDetailRouter: RouterInstanceType = {
  name: 'ReplenishmentTaskDetailRouter',
  Component: ReplenishmentTaskDetail,
  path: '/dashboard/replenishment-task/:id',
  routeType: 'PRIVATE',

  allowedRoles: [
    'AUTH_SHIFT_LEAD',
    'AUTH_HUB_INV_SPV',
    'AUTH_CENTRAL_SUPERVISOR',
    'AUTH_OUTBOUND_STAFF',
    'AUTH_INVENTORY_CONTROLLER',
    'AUTH_LP',
    'AUTH_SUPPLY_CHAIN',
  ],
  isDashboard: true,
}

const ReplenishmentTaskPartialRouter: RouterInstanceType = {
  name: 'ReplenishmentTaskPartialRouter',
  Component: ReplenishmentTaskPartial,
  path: '/dashboard/replenishment-task/partial/:id',
  routeType: 'PRIVATE',

  allowedRoles: [
    'AUTH_SHIFT_LEAD',
    'AUTH_HUB_INV_SPV',
    'AUTH_CENTRAL_SUPERVISOR',
    'AUTH_OUTBOUND_STAFF',
    'AUTH_INVENTORY_CONTROLLER',
    'AUTH_LP',
    'AUTH_SUPPLY_CHAIN',
  ],
  isDashboard: true,
}

const ReplenishmentScheduleRouter: RouterInstanceType = {
  name: 'ReplenishmentScheduleRouter',
  Component: ReplenishmentSchedule,
  path: '/dashboard/replenishment-schedule',
  routeType: 'PRIVATE',

  allowedRoles: [
    'AUTH_SHIFT_LEAD',
    'AUTH_HUB_INV_SPV',
    'AUTH_CENTRAL_SUPERVISOR',
    'AUTH_OUTBOUND_STAFF',
    'AUTH_INVENTORY_CONTROLLER',
    'AUTH_LP',
    'AUTH_SUPPLY_CHAIN',
  ],
  isDashboard: true,
}

const UnloadingDetailRouter: RouterInstanceType = {
  name: 'InboundUnloadingDetailRouter',
  Component: InboundUnloadingDetail,
  path: '/dashboard/inbound-so/unloading/:id',
  routeType: 'PRIVATE',

  allowedRoles: [
    roles.AUTH_ADMIN,
    roles.AUTH_QUALITY_ENGINEERING,
    roles.AUTH_PRODUCT_MANAGER,
    roles.AUTH_ADMIN_VIEW_ONLY,
    roles.AUTH_SUPPLY_CHAIN,
    roles.AUTH_INVENTORY_CONTROLLER,
  ],
  isDashboard: true,
}

const LimitSKURouter: RouterInstanceType = {
  name: 'LimitSKURouter',
  Component: LimitSKUPage,
  path: '/dashboard/limit-sku',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_QUALITY_ENGINEERING',
    'AUTH_HO_PRODUCT_MANAGER',
    'AUTH_WH_MANAGER',
    'AUTH_WH_INV_MANAGER',
    'AUTH_SUPER_AREA_MANAGER',
    'AUTH_HUB_AREA_MANAGER',
  ],
  isDashboard: true,
}

const SupplyOrderRouter = [
  ReplenishmentTaskPartialRouter,
  ReplenishmentTaskDetailRouter,
  ReplenishmentTaskRouter,
  ReplenishmentScheduleRouter,
  UnloadingDetailRouter,
  LimitSKURouter,
]

export const SupplyOrderRouterDetail = generateRouterDetail(SupplyOrderRouter)

export default SupplyOrderRouter
